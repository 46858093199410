'use strict'
import Cookies from 'js-cookie'
import { GetServerSidePropsContext } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { initializeApollo } from 'src/graphql/apolloClient'
import PORTAL_ME from 'src/graphql/PermitPortalUser/queries/PortalMe'
import { PermitPortalUser, PortalMeResponse } from 'src/types/PermitPortalUser'

const ACCESS_TOKEN_COOKIE = 'citifyd_access_token'

let accessToken: string
let serverContext: GetServerSidePropsContext

export const getAccessToken = (): string => {
  if (serverContext) {
    accessToken = parseCookies(serverContext)[ACCESS_TOKEN_COOKIE]
  } else {
    serverContext = null
    accessToken = Cookies.get(ACCESS_TOKEN_COOKIE)
  }

  return accessToken
}

export const logoutUser = (context?: GetServerSidePropsContext) => {
  if (serverContext || context) {
    destroyCookie(serverContext, ACCESS_TOKEN_COOKIE)
    context.res.statusCode = 302
    context.res.setHeader('Location', `/login`)
  } else {
    serverContext = null
    Cookies.remove(ACCESS_TOKEN_COOKIE)
    location.href = '/login'
  }
}

export const authenticateUser = (token: string) => {
  if (serverContext) {
    destroyCookie(serverContext, ACCESS_TOKEN_COOKIE)
    setCookie(serverContext, ACCESS_TOKEN_COOKIE, token, {
      path: '/',
    })
  } else {
    Cookies.set(ACCESS_TOKEN_COOKIE, token)
  }
}

export const verifyAuthentication = async (
  context?: GetServerSidePropsContext,
): Promise<PermitPortalUser> => {
  serverContext = context

  const apolloClient = initializeApollo({ initialState: null })

  const { data } = await apolloClient.query<PortalMeResponse>({
    query: PORTAL_ME,
  })

  return data?.portal_me
}

export const generateAuthenticationHeaders = (accessToken?: string) => {
  const headers = {}
  accessToken = accessToken || getAccessToken()

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  return headers
}
