import { gql } from '@apollo/client'

import Session from '../../User/fragments/SessionFragment'

export default gql`
  fragment PermitPortalUser on PermitPortalUser {
    id
    name
    phoneCountryCode
    phoneNumber
    identifier
    accessExpiresAt
    createdAt
    activeSession {
      ...Session
    }
  }
  ${Session}
`
