import React, { createContext, useState } from 'react'

import { PermitContextProps } from 'src/types/Permit'

export const DEFAULT_VALUE = {
  permit: null,
  lotSelected: null,
  setLotSelected: () => null,
  setPermit: () => null,
}

export const PermitCtx = createContext<PermitContextProps>(DEFAULT_VALUE)

export const PermitProvider = ({ children }) => {
  const [permit, setPermit] = useState()
  const [lotSelected, setLotSelected] = useState()

  return (
    <PermitCtx.Provider
      value={{ permit, setPermit, lotSelected, setLotSelected }}
    >
      {children}
    </PermitCtx.Provider>
  )
}
