import { gql } from '@apollo/client'

import PermitPortalUser from '../fragments/PermitPortalUserFragment'

export default gql`
  query portal_me {
    portal_me {
      ...PermitPortalUser
    }
  }
  ${PermitPortalUser}
`
