import { gql } from '@apollo/client'

export default gql`
  fragment Session on Session {
    id
    accessToken
    lastRenewalCheckAt
    nextRenewalAt
    lastRenewalAt
    expiresAt
  }
`
