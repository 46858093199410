import React, { createContext } from 'react'

import {
  PermitPortalContextProps,
  PermitPortalProviderProps,
} from 'src/types/PermitPortal'

export const DEFAULT_VALUE = {
  permitPortal: null,
}

export const PermitPortalCtx =
  createContext<PermitPortalContextProps>(DEFAULT_VALUE)

export const PermitPortalProvider = ({
  children,
  permitPortal,
}: PermitPortalProviderProps) => {
  return (
    <PermitPortalCtx.Provider value={{ permitPortal }}>
      {children}
    </PermitPortalCtx.Provider>
  )
}
