/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ApolloProvider } from '@apollo/client'
import React from 'react'
import 'src/styles/global.css'
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'

import { NotificationProvider } from 'src/context/Notifications'
import { PermitProvider } from 'src/context/Permit'
import { PermitPortalProvider } from 'src/context/Portal'
import { useApollo } from 'src/graphql/apolloClient'
import variables from 'src/styles/breakpoints.module.scss'

import 'src/styles/react-datepicker.scss'

setConfiguration({
  maxScreenClass: 'xl',
  gutterWidth: 30,
  breakpoints: [
    variables.xxs,
    variables.xs,
    variables.sm,
    variables.md,
    variables.lg,
    variables.xl,
  ].map(breakpoint => parseInt(breakpoint.replace('px', ''))),
})

const App = ({ Component, pageProps }) => {
  const client = useApollo(pageProps)

  return (
    <ApolloProvider client={client}>
      <ScreenClassProvider>
        <NotificationProvider>
          <PermitPortalProvider permitPortal={pageProps.permitPortal}>
            <PermitProvider>
              <Component {...pageProps} />
            </PermitProvider>
          </PermitPortalProvider>
        </NotificationProvider>
      </ScreenClassProvider>
    </ApolloProvider>
  )
}

export default App
