import classNames from 'classnames'
import { useEffect, useRef } from 'react'

import Text from 'src/components/Text'
import { VariantProps } from 'src/types/Notifications'

import styles from './Notification.module.scss'

interface NotificationProps {
  duration?: number
  isVisible: boolean
  message: string
  onClose: () => void
  title?: string
  variant: VariantProps
}

const Notification: React.FunctionComponent<NotificationProps> = ({
  duration = 5000,
  isVisible,
  message,
  onClose,
  title,
  variant,
}: NotificationProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isVisible) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => onClose?.(), duration)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [duration, isVisible, onClose])

  return (
    <div
      className={classNames(styles.notification, {
        [styles.isVisible]: isVisible,
        [styles.error]: variant === 'error',
      })}
    >
      <button className={styles.closeButton} onClick={onClose}>
        &times;
      </button>
      {title && (
        <Text gutterBottom="small" lineHeight="large" weight="600">
          {title}
        </Text>
      )}
      {message && <Text lineHeight="large">{message}</Text>}
    </div>
  )
}

export default Notification
