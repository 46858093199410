import React, { useCallback, useState } from 'react'

import Notification from 'src/components/Notification'
import {
  NotificationContextProps,
  NotificationTypeProps,
  VariantProps,
} from 'src/types/Notifications'

export const NotificationContext =
  React.createContext<NotificationContextProps>({})

export function NotificationProvider({ children }) {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false)
  const [notificationMessage, setNotificationMessage] = useState<string>('')
  const [notificationTitle, setNotificationTitle] = useState<string>('')
  const [notificationVariant, setNotificationVariant] = useState<VariantProps>()

  const success = ({ message = '', title = '' }) => {
    setIsNotificationVisible(true)
    setNotificationMessage(message)
    setNotificationTitle(title)
    setNotificationVariant('success')
  }

  const error = ({ message = '', title = '' }) => {
    setIsNotificationVisible(true)
    setNotificationMessage(message)
    setNotificationTitle(title)
    setNotificationVariant('error')
  }

  const onClose = () => {
    setIsNotificationVisible(false)
  }

  const contextValue = {
    showNotification: {
      success: useCallback(
        ({ message, title }: NotificationTypeProps) =>
          success({ message, title }),
        [],
      ),
      error: useCallback(
        ({ message, title }: NotificationTypeProps) =>
          error({ message, title }),
        [],
      ),
    },
  }

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Notification
        isVisible={isNotificationVisible}
        message={notificationMessage}
        onClose={onClose}
        title={notificationTitle}
        variant={notificationVariant}
      />
    </NotificationContext.Provider>
  )
}
