import classNames from 'classnames'
import React from 'react'

import styles from './Text.module.scss'

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  as?: typeof React.Component | string
  children: React.ReactNode
  className?: string
  color?: 'default' | 'gray' | 'orange' | 'yellow' | 'white' | 'red'
  desktopSize?:
    | 'tiny'
    | 'small'
    | 'base'
    | 'medium'
    | 'large'
    | 'large2x'
    | 'large3x'
  gutterBottom?: 'small' | 'base'

  italic?: boolean

  lineHeight?: 'tiny' | 'small' | 'base' | 'large'
  onClick?: () => void
  size?: 'tiny' | 'small' | 'base' | 'medium' | 'large' | 'large2x' | 'large3x'
  textAlign?: 'left' | 'center' | 'right'
  textTransform?: 'none' | 'uppercase' | 'lowercase'
  weight?: '300' | '400' | '600' | '700' | '800'
}

const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    {
      color = 'default',
      children,
      gutterBottom,
      lineHeight = 'base',
      size = 'base',
      desktopSize,
      italic = false,
      textAlign,
      textTransform = 'none',
      weight = '400',
      as: Component = 'p',
      className,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <Component
        className={classNames(
          className,
          { [styles.italic]: italic },

          styles['textAlign-' + textAlign],
          styles['fontColor-' + color],
          styles['lineHeight-' + lineHeight],
          styles['size-' + size],
          styles['desktopSize-' + desktopSize],
          styles['textTransform-' + textTransform],

          styles['weight-' + weight],
          styles['gutterBottom-' + gutterBottom],
          className,
        )}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </Component>
    )
  },
)

export default Text
